:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #90de53;
  --toastify-color-warning: #fed74e;
  --toastify-color-error: #e45e52;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 48px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  --toastify-color-progress-light: linear-gradient(92.15deg, #a4b2ff 0%, #81ebac 63.54%, #e754d8 98.44%);

  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast--success {
  background-color:rgba(144, 222, 83, 0.10);
  border: 1px solid var(--toastify-color-success);
  color: var(--toastify-color-success);
}

.Toastify__toast--warning {
  background-color:rgba(254, 215, 78, 0.10);
  border: 1px solid var(--toastify-color-warning);
  color: var(--toastify-color-warning);

}

.Toastify__toast--error {
  background-color: rgba(228, 94, 82, 0.10);
  border: 1px solid var(--toastify-color-error);
  color: var(--toastify-color-error);

}
.Toastify__toast-icon {
  width: 24px;
  height: 24px;
  margin-inline-end: 0;
}


.Toastify__toast-container {
  padding: 0px 24px;
 }

 .Toastify__toast{
  padding: 0px 4px;
  backdrop-filter: blur(10px);
 }


.Toastify__toast::after {
  content: '';
  display: none;
  /* position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f2f4f8; */
}
